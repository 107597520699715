import HeaderView from "../../../components/header/headerView";
import React, {useContext, useEffect, useState} from "react";
import calendar from "../../../assets/icones/global/calendar.svg";
import timer from "../../../assets/icones/global/time_circle.svg";
import "../../../styles/views/bookings.css"
import IconButton from "../../../components/button/iconButton";
import {useSelector} from "react-redux";
import InputSelect from "../../../components/form/inputSelect/inputSelect";
import {ErrorContext} from "../../../context/errorContext";
import {useLoader} from "../../../context/loaderContext";
import {useParams} from "react-router";
import SingleDateRangePicker from "../../../components/calendar/singleView/singleDateRangePicker";
import Button from "../../../components/button/button";
import BookingManageAvailability from "../bookingSidebar/bookingManageAvailability";
import Table from "../../../components/table/table";
import {usePartner} from "../../../context/partnerContext";
import useBookingTimeSlots from "../../../hooks/parseBooking/useBookingTimeSlots";
import close from "../../../assets/icones/global/close-stroke.svg";
import edit from "../../../assets/icones/global/edit.svg";
import trash from "../../../assets/icones/global/trash_can_white.svg";
import BookingTimeSlotsServices from "../../../api/services/bookingTimeSlots";
import ModalBookingSlotDeleting from "../../../components/modal/modalDeleting/modalBookingSlotDeleting";
import ActivitiesServices from "../../../api/services/activities";

const BookingSchedule= () => {

    const partnerId = useParams().partnerId;
    const user = useSelector(state => state.auth.user);
    const {currentPartnerId, allPartners} = usePartner();

    const currentPartnerBooking = (() => {
        if (user.role === "partner") {
            // Safely access `partnerID` and `bookingPartnersID`
            const partner = user.partnerID?.find((partner) => partner._id === currentPartnerId);
            return partner?.bookingPartnersID || null; // Default to `null` if not found
        }
        // For other roles, access `allPartners`
        const partner = allPartners?.find((partner) => partner._id === currentPartnerId);
        return partner?.bookingPartnersID || null; // Default to `null` if not found
    })();


    const { showError } = useContext(ErrorContext);
    const { setLoading } = useLoader();
    const [refresh, setRefresh] = useState(false);
    const [firstActivitySchedule, setFirstActivitySchedule] = useState([]);

    const [bookingToModify, setBookingToModify] = useState(null);

    const [activitySelected, setActivitySelected] = useState("");
    const [dateSelected, setDateSelected] = useState(new Date());
    
    const { activities, bookingTimeSlots } = useBookingTimeSlots(currentPartnerBooking._id, partnerId, activitySelected, dateSelected, refresh);


    const [openManageAvailability, setOpenManageAvailability] = useState(false);
    const [openModalDelete, setOpenModalDelete] = useState(false);
    const [slotToDelete, setSlotToDelete] = useState(null);

    const calendarInfos = [
        {id: 1, name: "Jour ouvert", className: "white"},
        {id: 2, name: "Jour fermé", className: "grey"},
    ];

    const handleDeleteBookingSlot = () => {
        BookingTimeSlotsServices.deleteBookingTimeSlot(currentPartnerBooking._id, slotToDelete)
        .then((response) => {
            if(response.status === 200){
                setSlotToDelete(null);
                setRefresh(!refresh);
            }
        }).catch((error) => {
            showError(error);
        });
    }

    const parseSchedulePlan = (schedulePlan) => {
        const daysOfWeek = {
            "Lundi": 1,
            "Mardi": 2,
            "Mercredi": 3,
            "Jeudi": 4,
            "Vendredi": 5,
            "Samedi": 6,
            "Dimanche": 0
        };

        // Initialize an array to store the parsed schedule
        const parsedSchedule = [];

        // Loop through each day of the week
        Object.entries(daysOfWeek).forEach(([dayName, dayNumber]) => {
            // Find the schedule for the current day in the schedulePlan array
            const daySchedule = schedulePlan.find(schedule => schedule.dayOfWeek === dayName);

            // If a schedule is found for the current day
            if (daySchedule) {
                // Determine the status based on whether the day is closed or not
                const status = daySchedule.isClosed ? 'closed' : 'open';

                // Push an object representing the day and its status into the parsedSchedule array
                parsedSchedule.push({
                    dayOfWeek: dayNumber,
                    status: status
                });
            } else {
                // If no schedule is found for the current day, push an object with 'Closed' status
                parsedSchedule.push({
                    dayOfWeek: dayNumber,
                    status: 'closed'
                });
            }
        });

        return parsedSchedule;
    };



    useEffect(() => {
        const fetchActivities = async () => {
            setLoading(true);
            try {
                const activitiesResponse = await ActivitiesServices.getActivitiesByPartnerId(partnerId);
                if (activitiesResponse.data !== {}) {
                    const activities = activitiesResponse.data;
                    setFirstActivitySchedule(activities[0].schedule.schedulePlan);
                }else{
                    setFirstActivitySchedule([]);
                }
            } catch (error) {
                console.log("error");
            } finally {
                setLoading(false);
            }
        }

        fetchActivities();
    }, [partnerId]);

    const removeTimeSlot = (activityId, slotIndex) => {
        const newBookingTimeSlots = [...bookingTimeSlots];

        // Find the index of the activity
        const index = newBookingTimeSlots.findIndex(item => item.id === activityId);
        if (index === -1) {
            showError("Activity not found");
            return; // Exit if no activity is found
        }

        // Check if the slot index is valid
        if (slotIndex >= 0 && slotIndex < newBookingTimeSlots[index].timeSlots.length) {
            newBookingTimeSlots[index].timeSlots.splice(slotIndex, 1);
        } else {
            showError("Invalid slot index");
            return; // Exit if the slot index is out of range
        }

        // Assuming the slots should already be objects if that's what the API expects
        // No need to transform them again if they are correctly formatted upon fetching/loading

        // Update the booking time slots in the backend
        BookingTimeSlotsServices.updateBookingTimeSlot(currentPartnerBooking._id, newBookingTimeSlots[index].id, {
            timeSlots: newBookingTimeSlots[index].timeSlots
        })
            .then(response => {
                if (response.status === 200) {
                    setRefresh(refresh => !refresh);  // Toggle refresh state to trigger re-fetch or update
                } else {
                    showError(`Failed to update booking time slot: Server responded with status ${response.status}`);
                }
            }).catch(error => {
            showError(`Failed to update booking time slot: ${error.message || "Unknown error"}`);
        });
    };




    return(
        <div className={"main-container is__grey"}>
            <HeaderView title={`Gérer vos disponibilités`} isGoBack={false} />

            <div className={"booking__container__actions"}>
                <div className={"container__action_item"}>
                    <InputSelect
                        name={"partnerActivities"}
                        options={activities.map(activity => ({id: activity._id, name: activity.name, value: activity._id}))}
                        valueSelected={activitySelected}
                        placeholder={"Toutes les activités"}
                        onChange={(e) => setActivitySelected(e.target.value)}
                    />

                </div>
                <div className={"container__action_item"}>
                    <IconButton icon={calendar} text={"Ouvrir de nouvelles dates"} isPrimary={false} onClick={() => { setOpenManageAvailability(true); setBookingToModify(null)} }/>
                </div>
            </div>

            {
                currentPartnerBooking && currentPartnerBooking !== null ?
                    <div className={"booking__container__schedule"}>

                        <div className={"schedule__container__calendar"}>
                            <div className={"schedule__calendar__datepicker"}>
                                <SingleDateRangePicker setDateSelected={setDateSelected} arrayDays={firstActivitySchedule.length > 0 ? parseSchedulePlan(firstActivitySchedule) : []} />
                            </div>
                            <div className={"schedule__calendar__infos"}>
                                {
                                    calendarInfos.map((info) => (
                                        <div className={"schedule__calendar__infos__item"}>
                                            <div className={`schedule__calendar__infos__item__pastille ${info.className}`}></div>
                                            <p className={"text-14"}>{info.name}</p>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>

                        <div className={"booking__container__list"}>
                            {
                                bookingTimeSlots && bookingTimeSlots.length > 0 ?
                                    <Table
                                        columns={[
                                            {name: "Vos activités", align: "left", width: "100%", field: "activity"},
                                        ]}
                                        data={
                                            bookingTimeSlots.map(activity => {

                                                const activitySlots = activities.filter(item => item._id === activity.activityID)[0];

                                                return {
                                                    activity: (
                                                        <div className={"table__booking"}>
                                                            <img
                                                                src={activitySlots && activitySlots.activityImages ? activitySlots.activityImages.images[0] : ""}
                                                                alt={activitySlots && activitySlots.name}
                                                                className={"table__booking__img"}/>
                                                            <div className={"table__booking__col"}>
                                                                <div className={"table__booking__col__header"}>
                                                                    <p className={"text-16 bold"}>{activitySlots.name}</p>
                                                                    <div
                                                                        className={"table__booking__col__header__actions"}>
                                                                        <IconButton icon={trash} isRed={true}
                                                                                    onClick={() => {
                                                                                        setOpenModalDelete(true);
                                                                                        setSlotToDelete(activity.id)
                                                                                    }}/>
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    className={"time-slot__container__wrapper smaller"}>
                                                                    {activity.timeSlots.map((slot, slotIndex) => (
                                                                        <div key={slot._id}
                                                                             className="time-slot__container is__small ">
                                                                            <img src={timer} alt="timer"/>
                                                                            {slot.startTime} - {slot.endTime}
                                                                            <img src={close} alt="close"
                                                                                 className="close"
                                                                                 onClick={() => removeTimeSlot(activity.id, slotIndex)}/>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            })
                                        }
                                    />
                                    :
                                    <div className={"container__empty"}>
                                        <p className={"text-14"}>Vous n'avez pas encore de créneau horaire pour cette date</p>
                                        <Button text={"Ouvrir des dates"} isPrimary={true} onClick={() => setOpenManageAvailability(true)}/>
                                    </div>

                            }
                        </div>
                    </div>
                    :
                    <div className={"container__empty"}>
                        <p className={"text-14"}>Vous n'avez pas encore de planning de réservation</p>
                        <IconButton icon={calendar} text={"Ouvrir des dates"} isPrimary={true} onClick={() => setOpenManageAvailability(true)}/>
                    </div>
                }
            <BookingManageAvailability open={openManageAvailability} setOpen={setOpenManageAvailability} partnerActivities={activities} setRefresh={setRefresh} bookingToModify={bookingToModify} />
            <ModalBookingSlotDeleting isOpen={openModalDelete} closeModal={() => setOpenModalDelete(false)} deleteSlot={handleDeleteBookingSlot} />
        </div>
    )
}

export default BookingSchedule;